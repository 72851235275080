import consumer from "./consumer"

document.addEventListener('turbolinks:load', function() {

  var interview_container_id = document.getElementById('interview-messages').getAttribute('data-interview-id')
  console.log(`Test Dom Approach: ${interview_container_id}`)

  if (interview_container_id) {
    consumer.subscriptions.create({ channel: "InterviewChannel", interview_id: interview_container_id }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Connected to InterviewChannel...')
        console.log(`interview_id set as ${ $('#interview-messages').attr('data-interview-id') }`)
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        $('#interview_question_answer').val('')

        var field_to_clean = document.getElementById('interview_question_answer');
        if (field_to_clean.inputmask){
          Inputmask.remove(field_to_clean);
          field_to_clean.removeAttribute("placeholder")
        }

        $('#interview_question_answer').attr("disabled", true);

        if (data.entry.responder == 'chatbot'){

          var field_to_mask = document.getElementById('interview_question_answer');
          if (field_to_mask && data.format == 'phone'){
            var im = new Inputmask("(99)99999999");
            im.mask(field_to_mask);
          } else if (field_to_mask && data.format == 'email') {
            var im = new Inputmask("email");
            im.mask(field_to_mask);
          } else if (field_to_mask && data.format == 'date') {
            var im = new Inputmask("99-99-9999");
            im.mask(field_to_mask);
          }

          $('#new_interview_question').attr("hidden", false)

          if (data.type == 'open_catch') {
            $('div.interview').append(
              `<div class="chat-message-container">
                <div class="row no-gutters">
                  <div class="col-auto text-center"> </div>
                  <div class="col">
                    <div class="message-content chatbot-message-format chat-bubble">
                      <p class="mb-1"> ${data.entry.answer} </p>
                      <div class="text-right"> </div>
                    </div>
                  </div>
                </div>
              </div>`
            )
          } else if (data.type == 'boolean') {

            $('#new_interview_question').attr("hidden", true)
            $('#interview_question_answer').attr("disabled", false);

            $('div.interview').append(
              `<div class="chat-message-container">
                <div class="row no-gutters">
                  <div class="col-auto text-center"> </div>
                  <div class="col">
                    <div class="message-content chatbot-message-format chat-bubble">
                      <p class="mb-1"> ${data.entry.answer} </p>
                      <div class="text-right"> </div>
                      <div style="text-align: center; margin-top: 10px;">
                        <button type="button" class="btn btn-primary" style="min-width: 100px;" onclick='respondFromChatbotOptions(this);'>Sí</button>
                        <button type="button" class="btn btn-secondary" style="min-width: 100px;" onclick='respondFromChatbotOptions(this);'>No</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`
            )
          } else if (data.type == 'catalog') {

            $('#new_interview_question').attr("hidden", true)
            $('#interview_question_answer').attr("disabled", false);

            var options_list = data.options.map(function(element) {
              return `<button type="button" class="btn btn-primary" style="width: 100%; margin-bottom: 10px;" onclick='respondFromChatbotOptions(this);'>${element}</button>`
            });

            $('div.interview').append(
              `<div class="chat-message-container">
                <div class="row no-gutters">
                  <div class="col-auto text-center"> </div>
                  <div class="col">
                    <div class="message-content chatbot-message-format chat-bubble">
                      <p class="mb-1"> ${data.entry.answer} </p>
                      <div class="text-right"> </div>
                      <div style="text-align: center; margin-top: 10px;">
                        ${options_list.join().replace(/,/g,' ')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>`
            )
          } else if (data.type == 'file') {

            $('#new_interview_question').attr("hidden", true)
            $('#interview_question_answer').attr("disabled", false);

            $('div.interview').append(
              `<div class="chat-message-container">
                <div class="row no-gutters">
                  <div class="col-auto text-center"> </div>
                  <div class="col">
                    <div class="message-content chatbot-message-format chat-bubble">
                      <p class="mb-1"> ${data.entry.answer} </p>
                      <div class="text-right"> </div>
                      <div style="text-align: center; margin-top: 10px;">

                        <form enctype="multipart/form-data" action="/applications/upload_file?uuid=${/((\w{4,12}-?)){5}/.exec(window.location.search)[0]}" accept-charset="UTF-8" data-remote="true" method="post">
                          <input type="hidden" name="_method" value="patch">
                          <div class="form-group">
                            <div class="input-group">
                              <input type="file" name="application[file]" id="application_file">
                            </div>
                          </div>
                          <br>
                          <div class="form-group">
                            <div class="input-group">
                              <div class="col-md-12">
                                <input type="submit" name="commit" value="Confirmar" class="btn btn-primary" data-disable-with="Confirmar">
                              </div>
                            </div>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>`
            )
          } else if (data.type == 'closing') {

            $('#new_interview_question').attr("hidden", true)
            $('#interview_question_answer').attr("disabled", false);

            $('div.interview').append(
              `<div class="chat-message-container">
                <div class="row no-gutters">
                  <div class="col-auto text-center"> </div>
                  <div class="col">
                    <div class="message-content chatbot-message-format chat-bubble">
                      <p class="mb-1"> ${data.entry.answer} </p>
                      <div class="text-right"> </div>
                    </div>
                  </div>
                </div>
              </div>`
            )
          }
        } else {
          $('div.interview').append(
            `<div class="chat-message-container">
              <div class="row no-gutters">
                <div class="col-auto text-center"> </div>
                <div class="col">
                  <div class="message-content applicant-message-format answer-chat-bubble">
                    <p class="mb-1"> ${data.entry.answer} </p>
                    <div class="text-right"> </div>
                  </div>
                </div>
              </div>
            </div>`
          )
        }

        if (data.entry.responder == 'chatbot'){
          $('#typing-bubble').remove()
          $('#interview_question_answer').attr("disabled", false);
          $('#interview_question_answer').focus();
        } else {
          $('div.interview').append('<div id="typing-bubble" class="chat-bubble"> <div class="typing"> <div class="dot"></div> <div class="dot"></div> <div class="dot"></div> </div> </div>')
        }

        var scroll_container = document.getElementsByClassName('interview')[0];
        scroll_container.scrollTop = scroll_container.scrollHeight;
      }
    });
  }
});
import consumer from "./consumer"

consumer.subscriptions.create("ChatGroupChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $('#chat_group_message_message').val('')
    $('div.chat').append('<div class="chat-message-container"> <div class="row no-gutters"> <div class="col-auto text-center"> <img src=' + data.extras.picture_url + ' class="avatar" alt=""> </div> <div class="col"> <div class="message-content"> <strong>' + data.extras.user_name + '</strong> <p class="mb-1">' + data.entry.message + '</p> <div class="text-right"> <small> ' + data.entry.created_at + ' </small> </div> </div> </div> </div> </div>')
  }
});
